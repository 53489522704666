<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
      </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'brandschaden-bingen',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/brandschaden-bingen',
      imageCount: 24,
      imageFileFormat: '.jpg',
      name: 'Wiederherstellungsarbeiten nach Brandschaden',
      description:
        'Nach dem Brandschaden in der Einliegerwohnung einer Villa in Bingen wurden sämtliche Sanierungsarbeiten im und am Haus durchgeführt. Eine fachmänniche Wiederherstellung wurde von uns in mehreren Etappen durch verschiedensten Arbeiten erfolgreich abgeschloßen. Dieses Projekt umfasste wieder mal einige Gewerke, wie z. Bsp.: Wärmedämmung, Verputz-, Estrich-, Trockenbau-, Fliesen- und Malerarbeiten. Einige natürliche Baustoffe wie Lehmputz wurden dabei verwendet und verarbeitet.',
    },
    //
  }),
};
</script>
